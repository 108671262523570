/* eslint-disable @typescript-eslint/no-explicit-any */

import { ModelClass }            from '@mathquis/modelx/lib/types/collection';
import { Model }                 from '@mathquis/modelx/';
import { Collection }            from '@mathquis/modelx';
import { AbstractApiCollection } from 'Collections/AbstractApiCollection';
import _cloneDeep                from 'lodash/cloneDeep';
import _flatten                  from 'lodash/flatten';
import _get                      from 'lodash/get';
import _set                      from 'lodash/set';
import AbstractApiModel          from 'modelx/models/abstracts/AbstractApiModel';
import moment                    from 'moment';

const CacheKey = 'api_connector_cache';

export const cacheEnabled = true;

class ModelCache {
	public data = {};

	public constructor() {
		const data = JSON.parse(sessionStorage.getItem(CacheKey) || '{}');

		this.data = this._dataWithoutExpired(data);
	}

	public destroy() {
		this.data = {};

		sessionStorage.removeItem(CacheKey);
	}

	public getCollectionResponse<T extends Model>(collection: Collection<T>, options) {
		const coll = collection as unknown as AbstractApiCollection<AbstractApiModel>;
		const duration = this._getCacheDuration(collection.model, options);

		if (cacheEnabled && duration) {
			const resData = _get(this.data, ModelCache.getPath(coll.model, options)) || {};
			const response = resData['response'];

			if (response) {
				const total = response?.data?.['hydra:member'].length || 0;

				console.info('ModelCache', 'found', 'coll', collection.model.path, `${total} models`);

				return response;
			}
		}

		return false;
	}

	public getModelResponse(model: Model, options): any {
		const data = _get(this.data, ModelCache.getModelPath(model));
		const duration = this._getCacheDuration(model, options);

		if (cacheEnabled && duration && data) {
			const arr = _flatten(Object.values(data).map(i => (
				(i as never)['response']['data']['hydra:member']
				|| [(i as never)['response']['data']]
			)));

			// noinspection EqualityComparisonWithCoercionJS
			const response: any = arr.find(d => d['id'] == model.id);

			if (response) {
				console.info('ModelCache', 'found', 'model', model.path);

				return response;
			}
		}

		return false;
	}

	public removeCacheForModel(model: Model | typeof Model) {
		if (this._getCacheDuration(model, {})) {
			const modelPath = `${ModelCache.getServicePrefix(model as unknown as AbstractApiModel)}${ModelCache.getModelPath(model as unknown as Model)}`;

			_set(this.data, modelPath, {});

			sessionStorage.setItem(CacheKey, JSON.stringify(this._dataWithoutExpired()));

			console.log('ModelCache', 'removed', modelPath);
		}
	}

	public saveCollectionResponse<T extends Model>(collection: Collection<T>, response, options) {
		const coll = collection as unknown as AbstractApiCollection<AbstractApiModel>;
		const duration = this._getCacheDuration(coll.model, options);

		if (cacheEnabled && duration && !options.params.search) {
			this._set(ModelCache.getPath(coll.model, options), response, duration);

			const total = response?.data?.['hydra:member'].length || 0;

			console.info('ModelCache', 'save', ModelCache.getPath(coll.model, options), `${total} models`);
		}
	}

	public saveModelResponse(model: Model, response, options) {
		const duration = this._getCacheDuration(model, options);

		if (cacheEnabled && !!duration) {
			this._set(ModelCache.getPath(model as never, { id: model.id }), response, duration);

			console.info('ModelCache', 'save', model.path);
		}
	}

	public static getKey<T extends AbstractApiModel>(model: ModelClass<T>, options) {
		const params = options.params || {};
		const paramsStr = JSON.stringify(params, Object.keys(params).sort())
			.replaceAll('.', '')
			.replaceAll(':', '');

		return `${escape(model.path + paramsStr)}`;
	}

	public static getModelPath(model: Model | typeof Model) {
		return model.constructor['path'] || model.path;
	}

	public static getPath<T extends AbstractApiModel>(model: ModelClass<T>, options) {
		return `${ModelCache.getServicePrefix(model as unknown as AbstractApiModel)}${ModelCache.getModelPath(model as unknown as Model)}.${ModelCache.getKey(model, options)}`;
	}

	public static getServicePrefix(model: AbstractApiModel) {
		if (model.serviceUrn) {
			const parts = model.serviceUrn.split(':');
			return parts[1];
		}

		return '';
	}

	private _dataWithoutExpired(data = this.data) {
		const cloneData = _cloneDeep(data);

		Object.keys(cloneData).forEach(modelPath => {
			Object.keys(cloneData[modelPath]).forEach(key => {
				const exp = cloneData[modelPath][key]['exp'];
				const isExpired = !exp || !moment(exp).isAfter();

				if (isExpired) {
					delete cloneData[modelPath][key];
				}
			});

			if (!Object.keys(cloneData[modelPath]).length) {
				delete cloneData[modelPath];
			}
		});

		return cloneData;
	}

	private _getCacheDuration(model, options) {
		return options.cache || model['cacheDuration'] || model.constructor['cacheDuration'];
	}

	private _set(path: string, response, duration: number | boolean) {
		const exp = (typeof duration === 'boolean' || duration === 0) ? undefined : moment().add(duration, 'seconds');

		_set(this.data, path, { exp, response });

		sessionStorage.setItem(CacheKey, JSON.stringify(this._dataWithoutExpired()));
	}
}

export default new ModelCache();