import InvoiceItemModel       from 'Models/invoice/InvoiceItemModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('InvoiceItemMeta')
@model.urnResource('invoice_item_meta')
@doc.path('/invoice_item_metas/{?id}')
export default class InvoiceItemMetaModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'invoiceItem': id;
		'invoiceItem.invoiceItemGroup': id;
		'invoiceItem.invoiceItemGroup.invoice': id;
		'reference': InvoiceInvoiceItemMetaReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.model(InvoiceItemModel) declare invoiceItem: InvoiceItemModel;
	@doc.string declare reference: InvoiceInvoiceItemMetaReference;
	@doc.string declare service: string;
	@doc.string declare value: string;

	public get invoiceItemId(): id {
		return this.get('invoiceItem.id', 0);
	}
}