import TaskZoneModel                 from 'Models/intervention/TaskZoneModel';
import OwnerClientLocationModel      from 'Models/time/OwnerClientLocationModel';
import OwnerPlanSourceReferenceModel from 'Models/time/OwnerPlanSourceReferenceModel';
import PlanSourceModel               from 'Models/time/PlanSourceModel';
import PlanStatusModel               from 'Models/time/PlanStatusModel';
import doc                           from 'decorators/doc';
import model                         from 'decorators/model';
import { Timestampable }             from 'helpers/traits';
import { Blamable }                  from 'helpers/traits';
import { computed }                  from 'mobx';
import TimePrivateApiModel           from 'modelx/models/abstracts/TimePrivateApiModel';
import moment                        from 'moment';

@model.staticLabel(`Plan d'aide`)
@model.urnResource('plan')
@doc.path('/plans/{?id}')
export default class PlanModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'endingAt[after]': string;
		'endingAt[before]': string;
		'endingAt[strictly_after]': string;
		'endingAt[strictly_before]': string;
		'ownerClientLocation': id;
		'ownerClientLocation.locationUrn': string;
		'ownerPlanSourceReference': id;
		'ownerPlanSourceReference.owner': id;
		'ownerPlanSourceReference.owner.contactUrn': string;
		'ownerPlanSourceReference.owner.generalClientUrn': string;
		'ownerPlanSourceReference.owner.ownerClients.clientUrn': string;
		'ownerPlanSourceReference.owner.ownerClients.partitionUrn': string;
		'planStatus': id;
		'planStatus.reference': PlanStatusReference;
		'startingAt[after]': string;
		'startingAt[before]': string;
		'startingAt[strictly_after]': string;
		'startingAt[strictly_before]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'fullName': string;
		'id': string;
		'ownerPlanSourceReference.reference': string;
		'referenceEpa': string;
		'updatedAt': string;
	}> = {};

	@doc.date declare birthDate: Moment;
	@doc.string declare city: string;
	@doc.string declare civility: string;
	@doc.boolean declare closer: boolean;
	@doc.date declare endingAt: Moment;
	@doc.date declare firstEndingAt: Moment;
	@doc.string declare fullName: string;
	@doc.number declare gir: number;
	@doc.number declare hourlyAmountSupported: number;
	@doc.string declare hourlyCoverageRate: string;
	@doc.date declare integrationDate: Moment;
	@doc.model(OwnerClientLocationModel) declare ownerClientLocation: OwnerClientLocationModel;
	@doc.model(OwnerPlanSourceReferenceModel) declare ownerPlanSourceReference: OwnerPlanSourceReferenceModel;
	@doc.string declare phoneNumber: string;
	@doc.model(PlanSourceModel) declare planSource: PlanSourceModel;
	@doc.model(PlanStatusModel) declare planStatus: PlanStatusModel;
	@doc.string declare referenceEpa: string;
	@doc.string declare referenceExportBeneficiary: string;
	@doc.number declare remainingHourlyAmount: number;
	@doc.string declare remainingHourlyCoverage: string;
	@doc.string declare serviceType: string;
	@doc.date declare startingAt: Moment;
	@doc.number declare timeGranted: number;
	@doc.string declare zipCode: string;

	@computed
	public get endingAtIsPassed() {
		return !!this.endingAt?.isSameOrBefore(moment());
	}

	@model.resolvable(TaskZoneModel, { attributeName: 'locationUrn' })
	public declare location: TaskZoneModel;
}