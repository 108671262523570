import { PlusCircleOutlined }   from '@ant-design/icons';
import { SortWaySet }           from 'Collections/AbstractApiCollection';
import { ApiCollection }        from 'Collections/ApiCollection';
import { LoadMoreCollection }   from 'Collections/LoadMoreCollection';
import Empty                    from 'antd/lib/empty';
import Select                   from 'antd/lib/select';
import { SelectProps }          from 'antd/lib/select';
import AbstractForm             from 'components/AbstractForm/AbstractForm';
import AppForm                  from 'components/AppForm';
import ButtonEllipsis           from 'components/ButtonEllipsis';
import SelectLoadMore           from 'components/SelectLoadMore/SelectLoadMore';
import { ISelectLoadMoreProps } from 'components/SelectLoadMore/SelectLoadMore';
import SingleRowSkeleton        from 'components/SingleRowSkeleton';
import View                     from 'components/View';
import _groupBy                 from 'lodash/groupBy';
import _uniqueId                from 'lodash/uniqueId';
import { observer }             from 'mobx-react';
import { computed }             from 'mobx';
import AbstractApiModel         from 'modelx/models/abstracts/AbstractApiModel';
import React                    from 'react';
import { getIdFromIri }         from 'tools/UrnTools';
import { getIdFromUrn }         from 'tools/UrnTools';
import actionEditModel          from 'tools/actionEditModel';
import getPrimaryColor          from 'tools/getPrimaryColor';
import sortBy                   from 'tools/sortBy';

const getChildrenText = (children, str: string): string => {
	if (typeof children === 'string') {
		return str + ' ' + children;
	} else if (Array.isArray(children)) {
		return children.map(child => getChildrenText(child, str)).join(' ');
	} else if (children && children.props) {
		return getChildrenText(children.props.children, str);
	}

	return '';
};

export interface ISelectCollectionProps<T extends AbstractApiModel>
	extends Partial<Omit<SelectProps<T>, 'value' | 'defaultValue'>> {

	autoFocus?: boolean;
	collection?: LoadMoreCollection<T> | ApiCollection<T>;
	defaultOpen?: boolean;
	defaultValue?: string | string[];
	disabledOption?: (m: T) => boolean;
	dropdownMatchSelectWidth?: boolean;
	fetchOnMount?: boolean;
	filterModels?: (m: T) => boolean;
	filters?: ModelFilters<T>;
	form?: typeof AbstractForm<{ model?: T; onSuccess?: (model: T) => void }>;
	groupBy?: (m: T) => string;
	isLoading?: boolean;
	loading?: boolean;
	loadingModel?: (m: T) => boolean;
	localSearchProperty?: (model: T) => string;
	modelClass: new () => T;
	multiple?: boolean;
	onChange?: (value, options) => void;
	onClear?: () => void;
	onDeselect?: () => void;
	onModelChange?: (model: T | null | undefined, value: string | string[]) => void;
	onModelSelect?: (model: T | null | undefined, value: string) => void;
	onMount?: (ref: SelectCollection<T>) => void;
	onSearchSuccess?: (models: T[], coll: LoadMoreCollection<T> | ApiCollection<T>) => void;
	property?: 'id' | 'iri' | 'urn' | 'reference';
	ref?;
	renderOption: (m: T) => React.ReactNode;
	renderOptionSelected?: (m: T) => React.ReactNode;
	searchOnFirstOpen?: boolean;
	selectFirstOnSingleResult?: boolean;
	sortModels?: (m: T) => string;
	sortName: ModelSortName<T>;
	sortWay?: SortWaySet;
	step?: number;
	value?: string | string[];
}

@observer
export default class SelectCollection<T extends AbstractApiModel> extends React.Component<ISelectCollectionProps<T>> {

	public collection: LoadMoreCollection<T> | ApiCollection<T>;
	public state = { selectKey: _uniqueId() };

	protected _selectedCollection: ApiCollection<T>;

	private _defaultValue?: string | string[];
	private _isMounted = false;

	public constructor(props) {
		super(props);

		this.collection = props.collection || new LoadMoreCollection<T>(props.modelClass);
		this._selectedCollection = new ApiCollection<T>(props.modelClass);

		this._defaultValue = props.value || props.defaultValue || props.selectProps?.defaultValue;
	}

	@computed
	public get models() {
		const { loadingModel } = this.props;

		if (this.collection.hasFilter('search')) {
			return this.collection.models;
		}

		const selectedCollModelsToDisplay = this._selectedCollection.models.filter(model => {
			return !this.collection.models
				.filter(m => {
					if (loadingModel) {
						return !loadingModel(m);
					}

					return true;
				})
				.map(m => m.id)
				.includes(model.id);
		});

		const collModelsToDisplay = this.collection.models.filter(m => {
			if (loadingModel && this._selectedCollection.ids.includes(m.id)) {
				return !loadingModel(m);
			}

			return true;
		});

		return [...selectedCollModelsToDisplay, ...collModelsToDisplay];
	}

	public async activeFirstOption() {
		const { property } = this.props;

		this._onChange(this.collection.first()?.[property || 'id'].toString(), {});
	}

	public async componentDidMount() {
		const {
			fetchOnMount,
			onMount,
		} = this.props;

		this._isMounted = true;

		if (onMount) {
			onMount(this);
		}

		if (fetchOnMount) {
			await this.fetchAsync();
		}

		if (this._defaultValue) {
			await this._fetchSelectedIdsAsync();

			if (this._isMounted) {
				this.forceUpdate();
			}
		}
	}

	public async componentDidUpdate(prevProps) {
		const {
			selectFirstOnSingleResult,
			value,
		} = this.props;

		// Si les filtres ont changé
		if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
			// On remonte le composant selectLoadMore, de façons à ce qu'il refasse la requête à la prochaine ouverture
			this.collection.clear();

			this.setState({ selectKey: _uniqueId() });

			// force the fetch so we can have see if there is only one result
			if (selectFirstOnSingleResult) {
				await this.fetchAsync();
			}
		}

		if ((prevProps.value || '').toString() !== (value || '').toString()) {
			await this._fetchSelectedIdsAsync();
		}
	}

	public componentWillUnmount() {
		this._isMounted = false;
	}

	public async fetchAsync(search = '') {
		const {
			defaultActiveFirstOption,
			filters,
			onSearchSuccess,
			selectFirstOnSingleResult,
			sortName,
			sortWay,
			step,
		} = this.props;

		if (sortName) {
			this.collection.setSorts({ [sortName]: typeof sortWay === 'undefined' ? true : sortWay });
		}

		this.collection.setFilters({ search, ...(filters || {}) });

		if (this.collection instanceof LoadMoreCollection) {
			await this.collection
				.setItemsPerPage(step || 20)
				.loadMore();
		} else {
			await this.collection.list();
		}

		if (onSearchSuccess) {
			onSearchSuccess(this.models, this.collection);
		}

		if (
			defaultActiveFirstOption
			|| (
				selectFirstOnSingleResult
				&& this.collection.models.length === 1
			)
		) {
			await this.activeFirstOption();
		}
	}

	public render() {
		const {
			allowClear,
			defaultActiveFirstOption,
			defaultValue,
			fetchOnMount,
			filterOption,
			form,
			groupBy,
			isLoading,
			loading,
			localSearchProperty,
			mode,
			modelClass,
			multiple,
			onFocus,
			placeholder,
			renderOption,
			renderOptionSelected,
			searchOnFirstOpen,
			size,
			sortModels,
		} = this.props;

		let models = this.models;

		if (sortModels) {
			models = sortBy(models.slice(), sortModels);
		}

		const showSearch = typeof this.props.showSearch === 'undefined' ? true : this.props.showSearch;

		const additionalProps: Partial<ISelectLoadMoreProps<never>> = {};

		if (modelClass['cache'] || this.collection instanceof ApiCollection) {
			additionalProps.onSearch = undefined;

			if (!filterOption) {
				additionalProps.filterOption = (input, option) => {
					return getChildrenText(option, '').toLowerCase().indexOf(input.toLowerCase()) >= 0;
				};
			}
		}

		if (localSearchProperty) {
			additionalProps.optionFilterProp = 'label';
			additionalProps.filterOption = (i, o) => (o?.label as string || '').includes(i.toLowerCase());
		}

		if (!showSearch) {
			additionalProps.placeholder = placeholder || 'Sélectionner';
			additionalProps.onSearch = undefined;
		}

		if (typeof searchOnFirstOpen !== 'undefined') {
			additionalProps.searchOnFirstOpen = searchOnFirstOpen;
		}

		if (typeof loading !== 'undefined') {
			additionalProps.loading = loading;
		}

		if (typeof isLoading !== 'undefined') {
			additionalProps.isLoading = isLoading;
		}

		const dropdownMatchSelectWidth = typeof this.props.dropdownMatchSelectWidth === 'undefined' ?
			false : this.props.dropdownMatchSelectWidth;

		return (
			<View gap={8} row>
				<SelectLoadMore
					allowClear={typeof allowClear === 'undefined' ? true : allowClear}
					autoFocus={this.props.autoFocus}
					defaultOpen={this.props.defaultOpen}
					defaultValue={defaultValue as never}
					disabled={this.props.disabled}
					dropdownMatchSelectWidth={dropdownMatchSelectWidth}
					dropdownStyle={this.props.dropdownStyle}
					filterOption={false}
					isFailed={this.collection.isFailed}
					isLoading={this.collection.isLoading}
					key={this.state.selectKey}
					loading={this.collection.isLoading}
					mode={multiple ? 'multiple' : mode}
					onBlur={this._onBlur}
					onChange={this._onChange}
					onClear={this.props.onClear}
					onDeselect={this.props.onClear}
					onDropdownVisibleChange={this.props.onDropdownVisibleChange}
					onFocus={onFocus}
					onLoadMore={this._onLoadMore}
					onSearch={this._onSearch}
					onSelect={this._onSelect}
					optionLabelProp={renderOptionSelected ? 'selectedLabel' : undefined}
					placeholder={placeholder || 'Rechercher'}
					searchOnFirstOpen={!fetchOnMount && !defaultActiveFirstOption && !this.collection.models.length}
					showSearch={showSearch}
					size={typeof size === 'undefined' ? 'large' : size}
					style={this.props.style}
					value={this.getValue() as unknown as never}

					{...additionalProps}
				>

					{(!!renderOption && models.length) ? (
						groupBy ? (
							Object.values(_groupBy(models, groupBy)).map(groupedModels => {
								const groupLabel = groupBy(groupedModels[0]);

								return (
									<Select.OptGroup key={groupLabel} label={groupLabel}>
										{this._renderModels(groupedModels)}
									</Select.OptGroup>
								);
							})
						) : (
							this._renderModels(models)
						)
					) : (
						<Select.Option key="_empty" value="">
							<Empty />
						</Select.Option>
					)}
				</SelectLoadMore>

				{!!form && !this.props.disabled && (
					<View center>
						<View
							color={'rgba(0, 0, 0, 0.25)'}
							hover={{ color: getPrimaryColor() }}
							onClick={this._onAdd}
							style={{ cursor: 'pointer' }}
							transition={false}
						>
							<PlusCircleOutlined title={`Ajouter ${modelClass['aStaticLabel']}`} />
						</View>
					</View>
				)}
			</View>
		);
	}

	/**
	 * Récupération des models dont l'"id" apparaît dans la propriété "value"
	 */
	protected async _fetchSelectedIdsAsync() {
		const { onSearchSuccess } = this.props;

		const ids = this.getValueIds().map(id => id.toString());
		const collectionIds = this.collection.ids.map(id => id.toString());
		const selectedCollectionIds = this._selectedCollection.ids.map(id => id.toString());

		this._defaultValue = undefined;

		// Si certains ids dans la value n'ont pas encore été fetchés
		if (
			ids.some(r => !collectionIds.includes(r)) &&
			ids.some(r => !selectedCollectionIds.includes(r)) &&
			!this._selectedCollection.isLoading &&
			!this.collection.isLoading
		) {
			await this._selectedCollection.clear().listBy(ids as never);

			if (onSearchSuccess) {
				onSearchSuccess(this.models, this.collection);
			}
		}
	}

	private _clearResults = () => {
		const selectedIds = this.getValueIds();

		this._selectedCollection.set([
			...this._selectedCollection.filter(m => selectedIds.includes(m.id.toString())),
			...this.collection.models
				.filter(m => !this._selectedCollection.ids.includes(m.id))
				.filter(m => selectedIds.includes(m.id.toString())),
		]);

		this.collection.clear();
	};

	private _onAdd = () => {
		const { form, modelClass, multiple, value } = this.props;
		const property = this.props.property || 'id';
		const oldValue = Array.isArray(value) ? value : [value];

		if (form) {
			AppForm.open(form, {
				model: new (modelClass)(),
				onSuccess: (m) => this._onChange(multiple ? [...oldValue, m[property]] : m[property], {}),
			});
		}
	};

	private _onBlur = e => {
		const { onBlur } = this.props;

		if (this.collection.hasFilter('search')) {
			this._clearResults();

			this.setState({ selectKey: _uniqueId() });
		}

		if (onBlur) {
			onBlur(e);
		}
	};

	private _onChange = (v, options) => {
		const {
			onChange,
			onModelChange,
		} = this.props;

		const property = this.props.property || 'id';

		if (onChange) {
			onChange(v, options);
		}

		if (onModelChange) {
			onModelChange(this.collection.models.find(m => m[property] == v), v);
		}
	};

	private _onLoadMore = async (search: string) => {
		if (this.collection instanceof LoadMoreCollection) {
			if (!this.collection.total || this.collection.hasNextPage) {
				await this.fetchAsync(search);
			}
		} else if (!this.collection.isLoaded) {
			await this.fetchAsync(search);
		}
	};

	private _onSearch = async (search: string) => {
		this._clearResults();

		await this.fetchAsync(search);
	};

	private _onSelect = (v, options) => {
		const { onModelSelect, onSelect } = this.props;

		const property = this.props.property || 'id';

		if (onSelect) {
			onSelect(v, options);
		}

		if (onModelSelect) {
			onModelSelect(this.collection.models.find(m => m[property] == v), v);
		}
	};

	private _renderModels(models: T[]) {
		const {
			disabledOption,
			form,
			loadingModel,
			localSearchProperty,
			renderOption,
			renderOptionSelected,
		} = this.props;

		const property = this.props.property || 'id';

		return models.map(model => {
			const isLoading = loadingModel && loadingModel(model);
			const disabled = isLoading || !!disabledOption && disabledOption(model);
			const label = localSearchProperty ? localSearchProperty(model).toLowerCase() : undefined;

			return (
				<Select.Option
					disabled={disabled}
					key={model[property]}
					label={label}
					selectedLabel={renderOptionSelected ? renderOptionSelected(model) : undefined}
					value={model[property].toString()}
				>
					{isLoading ?
						<SingleRowSkeleton /> : (
							<View centerV row>
								<View flex>
									{renderOption(model)}
								</View>

								{!!form && (
									<div onMouseDown={e => e.stopPropagation()}>
										<ButtonEllipsis
											actions={[actionEditModel(model, form, {})]}
											size="small"
											stopPropagation
											type="link"
										/>
									</div>
								)}
							</View>
						)
					}
				</Select.Option>
			);
		});
	}

	private getValue() {
		const { mode, multiple, value } = this.props;

		if (typeof value === 'undefined') {
			return undefined;
		}

		const isMultiple = multiple || mode === 'multiple';

		return isMultiple ? (value || '').toString().split(',').filter(v => v !== '') : value;
	}

	private getValueIds() {
		const { property } = this.props;

		const value = this._defaultValue || this.getValue();
		const arr = value ? (Array.isArray(value) ? value : [value]) : [];

		return arr.map(v => {
			if (property === 'iri') {
				return getIdFromIri(v);
			}

			if (property === 'urn') {
				return getIdFromUrn(v);
			}

			return v;
		});
	}
}