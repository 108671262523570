import doc                  from 'decorators/doc';
import model                 from 'decorators/model';
import { Blamable }        from 'helpers/traits';
import { Timestampable }   from 'helpers/traits';
import TimePrivateApiModel from '../../abstracts/TimePrivateApiModel';

@model.staticLabel('Statut de période')
@model.urn('$', 'time', 'period_status')
@doc.path('/period_statuses/{?id}')
export default class PeriodStatusModel extends Blamable(Timestampable(TimePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'reference': PeriodStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'id': string;
		'updatedAt': string;
	}> = {};

	public static cacheDuration = 3600;

	@doc.string declare label: string;
	@doc.string declare reference: PeriodStatusReference;

	public get color() {
		switch (this.reference) {
			case 'open':
				return 'green';
			case 'closed':
				return 'red';
			default:
				return 'black';
		}
	}
}