import { urn }                from 'helpers/ModelDictionary';
import InvoicePrivateApiModel from '../../abstracts/InvoicePrivateApiModel';

export type PaymentMethodReference = 'direct_debit';

@urn('$', 'invoice', 'payment_method')
export default class PaymentMethodModel extends InvoicePrivateApiModel {

	public _sorts: ModelSortsExtended<{
		'label': string;
	}> = {};

	public static cacheDuration = 3600;

	static get path(): string {
		return '/payment_methods/{?id}';
	}

	public get label(): string {
		return this.get('label', '');
	}

	public get reference(): PaymentMethodReference {
		return this.get('reference', '');
	}

	public get shortLabel(): string {
		return this.get('shortLabel', '');
	}
}